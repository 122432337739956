import App from "/src/App.svelte";

let components = new Map();

// Set global svelte methods for external access to managing components
globalThis.svelteConnector = {
	showComponent: showComponent,
	setComponentProps: setComponentProps,
	getComponents: getComponents,
	destroyComponent: destroyComponent,
};

/**
 * @param {string} id
 */
function getComponents(id) {
	if (id) {
		return components.get(id);
	}
	return components;
}

/**
 * @param {string} id
 * @param {string} component - name of the component
 * @param {HTMLElement} targetElement
 * @param {object} extraData - object containing methods and properties to send to component
 */
function showComponent(id, component, targetElement, extraData) {
	components.set(
		id,
		new App({
			target: targetElement, // The html element where the componenet will be instantiated
			props: {
				component: component,
				extraData: extraData,
			},
		})
	);
}

/**
 * @param {string} id
 * @param {object} data - object containing key and value to set
 */
function setComponentProps(id, data) {
	const component = getComponents(id);
	component.$set(data);
}

/**
 * @param {string} id
 */
function destroyComponent(id) {
	const component = getComponents(id);
	component.$destroy();
	components.delete(id);
}

const publicExports = {
	components: components,
	run: {
		showComponent: showComponent,
	},
};

export default publicExports;
